<script>
	export let title = '';

	export let position = "top"
</script>

{#if title}
	<div data-title={title} class:bottom={ position === "bottom" }>
		<slot />
	</div>
{:else}
	<slot />
{/if}

<style>
	div {
		position: relative;
	}

	div:hover:after {
		content: attr(data-title);
		position: absolute;
		bottom: calc(100% + 5px);
		left: 50%;
		background-color: rgb(15, 15, 15);
		pointer-events: none;
		user-select: none;
		padding: 8px 8px;
		font-size: 12px;
		color: rgba(255, 255, 255, 0.9);
		white-space: nowrap;
		border-radius: 4px;
		transform: translateX(-50%);
	}

	div.bottom:after {
		bottom: auto;
		top: calc(100% + 5px);
	}
</style>
